import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLanguageSelectorComponent } from './custom-language-selector.component';
import { DropdownModule } from '@util/components/dropdown/dropdown.module';
import { IconModule } from '@util/components/icon/icon.module';
import { ColorThemeModule } from '@util/components/shared/color-theme/color-theme.module';


@NgModule({
  declarations: [
    CustomLanguageSelectorComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    IconModule,
    ColorThemeModule
  ],
  exports: [CustomLanguageSelectorComponent]
})
export class CustomLanguageSelectorModule { }
